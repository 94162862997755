/**
 * Created By : Venkat Salagrama
 */

import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbDateStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common'

// Services
import { ReportService } from '../../../services/report/report.service';
import { routerTransition } from '../../../services/config/config.service';
import { ApiService } from '../../../services/api/api.service';
import { APP_SETTINGS } from 'src/app/models/app-settings';

@Component({
	selector: 'app-report-list',
	templateUrl: './report-list.component.html',
	styleUrls: ['./report-list.component.css'],
	animations: [routerTransition()],
	host: { '[@routerTransition]': '' },
	providers: [DatePipe]
})

export class ReportListComponent implements OnInit {
  
	towersList = [];
	model: NgbDateStruct;

	userDisplayName: any;
	towerid: number; 
	date: Date; 
	latestDate : any;

	constructor(private router: Router, private reportService: ReportService, private toastr: ToastrService, private apiService: ApiService, public datepipe: DatePipe, private config: NgbDatepickerConfig) { 

		const currentDate = new Date();
		config.minDate = {year:currentDate.getFullYear()-5, month:currentDate.getMonth()+1, day: currentDate.getDate()};
		config.maxDate = {year:currentDate.getFullYear(), month:currentDate.getMonth()+1, day: currentDate.getDate()};
		config.outsideDays = 'hidden';

	}

	// Call student list function on page load
	ngOnInit() {	
		this.towerid = 0; 
		if (typeof(localStorage.getItem('selectedtowerid')) != 'undefined' && 
			localStorage.getItem('selectedtowerid') != null) {
				var selectedVal = localStorage.getItem('selectedtowerid'); 
				this.towerid = parseInt(selectedVal);
		} else {
			this.towerid = 0; 			
		}

		if (typeof(localStorage.getItem('selecteddate')) != 'undefined' && 
			localStorage.getItem('selecteddate') != null) {

			this.date=new Date(+JSON.parse(localStorage.getItem('selecteddate')).year, 
				JSON.parse(localStorage.getItem('selecteddate')).month-1, 
				JSON.parse(localStorage.getItem('selecteddate')).day);
			// this.latestDate = this.datepipe.transform(this.date, 'yyyy-MM-dd');
			this.latestDate = { day: this.date.getDate(), month: this.date.getMonth()+1, year: this.date.getFullYear() }

		} else {

			this.date=new Date();
			// this.latestDate = this.datepipe.transform(this.date, 'yyyy-MM-dd');
			this.latestDate = { day: this.date.getDate(), month: this.date.getMonth()+1, year: this.date.getFullYear() }

		}

		setTimeout(() => {
			let userInfo = localStorage.getItem('userInfo');
			if ((typeof(JSON.parse(userInfo).firstName) != 'undefined' && JSON.parse(userInfo).firstName != null) 
				&& (typeof(JSON.parse(userInfo).surname) != 'undefined' && JSON.parse(userInfo).surname != null)) {
				this.userDisplayName = JSON.parse(userInfo).firstName + " " + JSON.parse(userInfo).surname; 
			} else if (typeof(JSON.parse(userInfo).commonName) != 'undefined' && JSON.parse(userInfo).commonName != null) { 
				this.userDisplayName = JSON.parse(userInfo).commonName; 
			} else {
				this.userDisplayName = JSON.parse(userInfo).username; 
			}	
			this.getTowers();	
		}, 1500);
	}

	// Fetch All Towers
	getTowers() {
		this.apiService.getTowers().subscribe(res => {   
			   
				// console.log(res.value);
				// for(var i=0;i<res.value.length;i++) {
				// console.log(this.sortAlphaNumeric(res.value, "ASC", "name"));
				var dataSorted = this.apiService.sortAlphaNumeric(res.value, "ASC", "name"); 

				for(var i=0;i<dataSorted.length;i++) {
					var item = { 
						"id" : dataSorted[i].id, 
						"value" : dataSorted[i].name 
					};
					this.towersList.push(item); 
				}
				localStorage.setItem('towers', JSON.stringify(this.towersList));

			}, (e) => {			
		  console.log(e);       
			});
	
		// console.log(this.towersList); 
	}

	// Store In LocalStorage
	checkdata() {
		localStorage.setItem('selectedtowerid', this.towerid+"");
		localStorage.setItem('selecteddate', JSON.stringify(this.latestDate));
	}

	fetchDataset(towerid) {
		localStorage.removeItem('brdataset'); 
		localStorage.removeItem('irdataset'); 
		localStorage.removeItem('codataset'); 
		localStorage.removeItem('eqdataset'); 

		// Bach
		this.apiService.fetchDatasetByTowerIdAndLocation(towerid, "beach").subscribe(res => {      
			for(var i=0;i<res.value.results.length;i++) {
				if(res.value.results[i].location == towerid && 
					res.value.results[i].name.toLowerCase().includes(APP_SETTINGS.LIFEGUARD_BEACH_DATA.toLowerCase())) {
					localStorage.setItem('brdataset', res.value.results[i].id+"");
				}
			}
		}, (e) => {			
			console.log(e);       
		});

		// Incident
		this.apiService.fetchDatasetByTowerIdAndLocation(towerid, "incident").subscribe(res => {      
			for(var i=0;i<res.value.results.length;i++) {				
				if(res.value.results[i].location == towerid && 
					res.value.results[i].name.toLowerCase().includes(APP_SETTINGS.LIFEGUARD_INCIDENT_DATA.toLowerCase())) {
					localStorage.setItem('irdataset', res.value.results[i].id+"");
				}
			}
		}, (e) => {			
			console.log(e);       
		});
		
		// Conditions
		this.apiService.fetchDatasetByTowerIdAndLocation(towerid, "condition").subscribe(res => {      
			for(var i=0;i<res.value.results.length;i++) {				
				if(res.value.results[i].location == towerid && 
					res.value.results[i].name.toLowerCase().includes(APP_SETTINGS.LIFEGUARD_CONDITIONS_DATA.toLowerCase())) {
					localStorage.setItem('codataset', res.value.results[i].id+"");
				}
			}
		}, (e) => {			
			console.log(e);       
		});

		// Equipment
		this.apiService.fetchDatasetByTowerIdAndLocation(towerid, "equipment").subscribe(res => {      
			for(var i=0;i<res.value.results.length;i++) {				
				if(res.value.results[i].location == towerid && 
					res.value.results[i].name.toLowerCase().includes(APP_SETTINGS.LIFEGUARD_EQUIPMENT_DATA.toLowerCase())) {
					localStorage.setItem('eqdataset', res.value.results[i].id+"");
				}
			}
		}, (e) => {			
			console.log(e);       
		});
	}

	// Logout User
	logOut() {
		this.toastr.success('Success', "Logged Out Successfully");
		this.apiService.clearSession(); 
		/*localStorage.removeItem('userData');
		localStorage.removeItem('userInfo');
		localStorage.removeItem('selectedtowerid');
		localStorage.removeItem('selecteddate');
		localStorage.removeItem('brdataset');
		localStorage.removeItem('irdataset');*/
		this.router.navigate(['/login']);
	}

}
/**
 * Created By : Venkat Salagrama
 */

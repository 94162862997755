/**
 * Created By : Venkat Salagrama
 */

import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbDateStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common'

// Services
import { ReportService } from '../../../services/report/report.service';
import { routerTransition } from '../../../services/config/config.service';
import { ApiService } from '../../../services/api/api.service';
import { KeyValueChanges, KeyValueDiffer, KeyValueDiffers } from '@angular/core';
import * as moment from 'moment-timezone';
import { APP_SETTINGS } from 'src/app/models/app-settings';

@Component({
	selector: 'app-report-details',
	templateUrl: './report-details.component.html',
	styleUrls: ['./report-details.component.css'],
	animations: [routerTransition()],
	host: { '[@routerTransition]': '' },
	providers: [DatePipe]
})

export class ReportDetailsComponent implements OnInit {

	towersList = [];
    model: NgbDateStruct;

	userDisplayName: any;
	towerid: number;
	date: Date;
	latestDate : any;

	existingDatetime: '';

	showFormSubmissionLoader: boolean = false;

	requestJson: any;

	selectedFiles1: FileList;
	selectedFiles2: FileList;

	private latestDateDiffer: KeyValueDiffer<string, any>;

	incidentForm = this.formBuilder.group({

		timeOfIncident : [''],
		timeNotified : [''],
		timeTerminated : [''],
		patientName	: [''],
		nationalityEthnicity : [''],
		natureOfIncident : [''],
		beachLocation : [''],
		distanceFromShore : [''],
		brieflyDescribePatient : [''],
		howAlerted : [''],
		inFlaggedArea : [''],
		lifeguardsResponding : [''],
		witnessName	: [''],
		surfConditions	: [''],
		swellSize : [''],
		windDirection : [''],
		mediaAttended : [''],
		comments : [''],
		patinetConscious : [''],
		theAirwayPresent : [''],
		breathingPresent : [''],
		pulsePresent : [''],
		cprPerformed : [''],
		o2EquipmentUsed : [''],
		suction : [''],
		mechanicalResuscitation : [''],
		oxygenTherapy : [''],
		defibrillation : [''],
		otherFirstaidRendered : [''],
		patientsConditionOnDisposal	: [''],
		otherRespondingAgencies	: [''],
		patientsRefusedAttention : [''],
		reportingLifeguard : [''],
		// lifeguardSuperintendent	: [''],
		TowerId : [''],
		Media1: [''],
		Media2: [''],

		otherRespondingAgencies1: [''],
		otherRespondingAgencies2: [''],
		otherRespondingAgencies3: [''],
		otherRespondingAgencies4: [''],
		otherRespondingAgencies5: ['']
	});

	Media1Path: string;
	Media2Path: string;

	constructor(private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private reportService: ReportService, private toastr: ToastrService, private apiService: ApiService, public datepipe: DatePipe, private differs: KeyValueDiffers, private config: NgbDatepickerConfig) {
		// Get user detail index number sent in params
		this.route.params.subscribe(params => {
		});

		const currentDate = new Date();
		config.minDate = {year:currentDate.getFullYear()-5, month:currentDate.getMonth()+1, day: currentDate.getDate()};
		config.maxDate = {year:currentDate.getFullYear(), month:currentDate.getMonth()+1, day: currentDate.getDate()};
		config.outsideDays = 'hidden';
	}

	ngOnInit() {
		this.towerid = 0;
		if (typeof(localStorage.getItem('selectedtowerid')) != 'undefined' &&
			localStorage.getItem('selectedtowerid') != null) {
				var selectedVal = localStorage.getItem('selectedtowerid');
				this.towerid = parseInt(selectedVal);
		} else {
			this.towerid = 0;
		}

		if (typeof(localStorage.getItem('selecteddate')) != 'undefined' &&
			localStorage.getItem('selecteddate') != null) {

			this.date=new Date(+JSON.parse(localStorage.getItem('selecteddate')).year,
				JSON.parse(localStorage.getItem('selecteddate')).month-1,
				JSON.parse(localStorage.getItem('selecteddate')).day);
			// this.latestDate = this.datepipe.transform(this.date, 'yyyy-MM-dd');
			this.latestDate = { day: this.date.getDate(), month: this.date.getMonth()+1, year: this.date.getFullYear() }

		} else {

			this.date=new Date();
			// this.latestDate = this.datepipe.transform(this.date, 'yyyy-MM-dd');
			this.latestDate = { day: this.date.getDate(), month: this.date.getMonth()+1, year: this.date.getFullYear() }

		}

		this.latestDateDiffer = this.differs.find(this.latestDate).create();

		let userInfo = localStorage.getItem('userInfo');
		if ((typeof(JSON.parse(userInfo).firstName) != 'undefined' && JSON.parse(userInfo).firstName != null)
			&& (typeof(JSON.parse(userInfo).surname) != 'undefined' && JSON.parse(userInfo).surname != null)) {
			this.userDisplayName = JSON.parse(userInfo).firstName + " " + JSON.parse(userInfo).surname;
		} else if (typeof(JSON.parse(userInfo).commonName) != 'undefined' && JSON.parse(userInfo).commonName != null) {
			this.userDisplayName = JSON.parse(userInfo).commonName;
		} else {
			this.userDisplayName = JSON.parse(userInfo).username;
		}

		this.incidentForm = this.formBuilder.group({
			date : this.date,
			towerid: this.towerid,
			towersList: this.towersList,
			latestDate: this.latestDate,

			timeOfIncident : '',
			timeNotified : '',
			timeTerminated : '',
			patientName	: '',
			nationalityEthnicity : '',
			natureOfIncident : '',
			beachLocation : '',
			distanceFromShore : '',
			brieflyDescribePatient : '',
			howAlerted : '',
			inFlaggedArea : 'No',
			lifeguardsResponding : '',
			witnessName	: '',
			surfConditions	: '',
			swellSize : 0,
			windDirection : '',
			mediaAttended : 'No',
			comments : '',
			patinetConscious : '',
			theAirwayPresent : 'No',
			breathingPresent : 'No',
			pulsePresent : 'No',
			cprPerformed : 'No',
			o2EquipmentUsed : 'No',
			suction : 'No',
			mechanicalResuscitation : 'No',
			oxygenTherapy : 'No',
			defibrillation : 'No',
			otherFirstaidRendered : '',
			patientsConditionOnDisposal	: '',
			otherRespondingAgencies	: '',
			patientsRefusedAttention : '',
			reportingLifeguard : '',
			// lifeguardSuperintendent	: '',
			TowerId : 0,
			Media1: null,
			Media2: null,

			otherRespondingAgencies1: '',
			otherRespondingAgencies2: '',
			otherRespondingAgencies3: '',
			otherRespondingAgencies4: '',
			otherRespondingAgencies5: ''
		});


		var irdata = localStorage.getItem('irdata');

		if (typeof(irdata) != 'undefined' && irdata != null) {

			var irdataJsonObject = JSON.parse(irdata);

			this.existingDatetime = irdataJsonObject.timestamp;

			// Fetch Existing data
			var createdDate = irdataJsonObject.timestamp;

			var irdataset = localStorage.getItem('irdataset');
			if (typeof(irdataset) != 'undefined' && irdataset != null) {
				// Fields
				this.apiService.fieldmappingOnBeachReport(irdataset).subscribe(resp => {

					// var	towerId = this.getValueFromList("Tower Id", resp.value);
					var	timeOfIncident = this.getValueFromList("Time Of Incident", resp.value);
					var	timeNotified = this.getValueFromList("Time Notified", resp.value);
					var	timeTerminated = this.getValueFromList("Time Terminated", resp.value);
					var	patientName = this.getValueFromList("Patient Name", resp.value);
					var nationalityEthnicity = this.getValueFromList("Nationality or Ethnicity", resp.value);
					var	natureOfIncident = this.getValueFromList("Nature Of Incident", resp.value);
					var	beachLocation = this.getValueFromList("Beach Location", resp.value);
					var	distanceFromShore = this.getValueFromList("Distance From Shore", resp.value);
					var	brieflyDescribePatient = this.getValueFromList("Briefly Describe Patient", resp.value);
					var	howAlerted = this.getValueFromList("How Alerted", resp.value);
					var	inFlaggedArea = this.getValueFromList("In Flagged Area", resp.value);
					var	lifeguardsResponding = this.getValueFromList("Lifeguards Responding", resp.value);
					var	witnessName = this.getValueFromList("Witness Name", resp.value);
					var	surfConditions = this.getValueFromList("Surf Conditions", resp.value);
					var	swellSize = this.getValueFromList("Swell Size", resp.value);
					var	windDirection = this.getValueFromList("Wind Direction", resp.value);
					var	mediaAttended = this.getValueFromList("Media Attended", resp.value);
					var	comments = this.getValueFromList("Comments", resp.value);
					var	patinetConscious = this.getValueFromList("What was the level of consciousness", resp.value);
					var	theAirwayPresent = this.getValueFromList("The Airway Clear", resp.value);
					var	breathingPresent = this.getValueFromList("Breathing Present", resp.value);
					var	pulsePresent = this.getValueFromList("Pulse Present", resp.value);
					var	cprPerformed = this.getValueFromList("Cpr Performed", resp.value);
					var	o2EquipmentUsed = this.getValueFromList("O2 Airbag Resuscitator", resp.value);
					var	suction = this.getValueFromList("Suction", resp.value);
					var	mechanicalResuscitation = this.getValueFromList("Was Guedel Airway Used", resp.value);
					var	oxygenTherapy = this.getValueFromList("Oxygen Therapy", resp.value);
					var	defibrillation = this.getValueFromList("Defibrillation", resp.value);
					var	otherFirstaidRendered = this.getValueFromList("Other FirstAid Rendered", resp.value);
					var	patientsConditionOnDisposal = this.getValueFromList("Patients Condition On Disposal", resp.value);
					var	otherRespondingAgencies = this.getValueFromList("Other Responding Agencies", resp.value);
					var	patientsRefusedAttention = this.getValueFromList("Patients Refused Attention", resp.value);
					var	reportingLifeguard = this.getValueFromList("Reporting Lifeguard", resp.value);
					// var	lifeguardSuperintendent = this.getValueFromList("Lifeguard Superintendent", resp.value);
					var	Media1 = this.getValueFromList("Media1", resp.value);
					var	Media2 = this.getValueFromList("Media2", resp.value);
					var	towerId = this.getValueFromList("Tower Id", resp.value);

					// this.toastr.success('Incident Report Record', 'Record is already exists!');

					var towerIdFromString = this.processTowerIdFromName(this.towerid);

					var otherRespondingAgenciesOrg = this.getFieldValue(irdataJsonObject, otherRespondingAgencies);
					var otherRespondingAgenciesOther = otherRespondingAgenciesOrg;
					if(typeof(otherRespondingAgenciesOther) != 'undefined' && otherRespondingAgenciesOther != null && otherRespondingAgenciesOther != '') {
						otherRespondingAgenciesOther = otherRespondingAgenciesOther.replace("Police,", "");
						otherRespondingAgenciesOther = otherRespondingAgenciesOther.replace("Police", "");
						otherRespondingAgenciesOther = otherRespondingAgenciesOther.replace("Ambulance,", "");
						otherRespondingAgenciesOther = otherRespondingAgenciesOther.replace("Ambulance", "");
						otherRespondingAgenciesOther = otherRespondingAgenciesOther.replace("Helicopter,", "");
						otherRespondingAgenciesOther = otherRespondingAgenciesOther.replace("Helicopter", "");
						otherRespondingAgenciesOther = otherRespondingAgenciesOther.replace("Fire Dept.,", "");
						otherRespondingAgenciesOther = otherRespondingAgenciesOther.replace("Fire Dept.", "");
						// console.log("otherRespondingAgenciesOther "+otherRespondingAgenciesOther);
					} else {
						otherRespondingAgenciesOther = '-';
					}

					this.incidentForm = this.formBuilder.group({
						date : this.date,
						towerid: this.towerid,
						towersList: this.towersList,
						latestDate: this.latestDate,
						timeOfIncident: this.getFieldValue(irdataJsonObject, timeOfIncident),
						timeNotified: this.getFieldValue(irdataJsonObject, timeNotified),
						timeTerminated: this.getFieldValue(irdataJsonObject, timeTerminated),
						patientName: this.getFieldValue(irdataJsonObject, patientName),
						nationalityEthnicity:  this.getFieldValue(irdataJsonObject, nationalityEthnicity),
						natureOfIncident: this.getFieldValue(irdataJsonObject, natureOfIncident),
						beachLocation: this.getFieldValue(irdataJsonObject, beachLocation),
						distanceFromShore: this.getFieldValue(irdataJsonObject, distanceFromShore),
						brieflyDescribePatient: this.getFieldValue(irdataJsonObject, brieflyDescribePatient),
						howAlerted: this.getFieldValue(irdataJsonObject, howAlerted),
						inFlaggedArea: this.getFieldValue(irdataJsonObject, inFlaggedArea) == 1 ? 'Yes' : 'No',
						lifeguardsResponding: this.getFieldValue(irdataJsonObject, lifeguardsResponding),
						witnessName: this.getFieldValue(irdataJsonObject, witnessName),
						surfConditions: this.getFieldValue(irdataJsonObject, surfConditions),
						swellSize: this.getFieldValue(irdataJsonObject, swellSize),
						windDirection: this.getFieldValue(irdataJsonObject, windDirection),
						mediaAttended: this.getFieldValue(irdataJsonObject, mediaAttended) == 1 ? 'Yes' : 'No',
						comments: this.getFieldValue(irdataJsonObject, comments),
						patinetConscious: this.getFieldValue(irdataJsonObject, patinetConscious),
						theAirwayPresent: this.getFieldValue(irdataJsonObject, theAirwayPresent) == 1 ? 'Yes' : 'No',
						breathingPresent: this.getFieldValue(irdataJsonObject, breathingPresent) == 1 ? 'Yes' : 'No',
						pulsePresent: this.getFieldValue(irdataJsonObject, pulsePresent) == 1 ? 'Yes' : 'No',
						cprPerformed: this.getFieldValue(irdataJsonObject, cprPerformed) == 1 ? 'Yes' : 'No',
						o2EquipmentUsed: this.getFieldValue(irdataJsonObject, o2EquipmentUsed) == 1 ? 'Yes' : 'No',
						suction: this.getFieldValue(irdataJsonObject, suction) == 1 ? 'Yes' : 'No',
						mechanicalResuscitation: this.getFieldValue(irdataJsonObject, mechanicalResuscitation) == 1 ? 'Yes' : 'No',
						oxygenTherapy: this.getFieldValue(irdataJsonObject, oxygenTherapy) == 1 ? 'Yes' : 'No',
						defibrillation: this.getFieldValue(irdataJsonObject, defibrillation) == 1 ? 'Yes' : 'No',
						otherFirstaidRendered: this.getFieldValue(irdataJsonObject, otherFirstaidRendered),
						patientsConditionOnDisposal: this.getFieldValue(irdataJsonObject, patientsConditionOnDisposal),
						// otherRespondingAgencies: this.getFieldValue(irdataJsonObject, otherRespondingAgencies),
						patientsRefusedAttention: this.getFieldValue(irdataJsonObject, patientsRefusedAttention),
						reportingLifeguard: this.getFieldValue(irdataJsonObject, reportingLifeguard),
						// lifeguardSuperintendent: this.getFieldValue(irdataJsonObject, lifeguardSuperintendent),
						Media1: '',
						Media2: '',

						otherRespondingAgencies1: ((typeof(otherRespondingAgenciesOrg) != 'undefined' && otherRespondingAgenciesOrg != null) && otherRespondingAgenciesOrg.includes("Police")) ? true : false,
						otherRespondingAgencies2: ((typeof(otherRespondingAgenciesOrg) != 'undefined' && otherRespondingAgenciesOrg != null) && otherRespondingAgenciesOrg.includes("Ambulance")) ? true : false,
						otherRespondingAgencies3: ((typeof(otherRespondingAgenciesOrg) != 'undefined' && otherRespondingAgenciesOrg != null) && otherRespondingAgenciesOrg.includes("Helicopter")) ? true : false,
						otherRespondingAgencies4: ((typeof(otherRespondingAgenciesOrg) != 'undefined' && otherRespondingAgenciesOrg != null) && otherRespondingAgenciesOrg.includes("Fire Dept.")) ? true : false,
						otherRespondingAgencies5: otherRespondingAgenciesOther.trim()
					});

					// console.log(irdataJsonObject);
					var recId = irdataJsonObject.id.id;
					var files = irdataJsonObject.files;

					if(files[Media1].contentType.includes("image")) {
						this.apiService.imageView(irdataset, Media1, recId).subscribe(resp => {
							this.Media1Path = 'data:image/png;base64,'+resp;
							$('#video-upload-wrap-id').hide();
							$('#video-upload-video-id').hide();
							$('#video-upload-video-id-f-m').hide();
							$('#video-upload-image-id').attr('src', 'data:image/png;base64,'+resp);
							$('#video-upload-image-id-f-m').attr('src', 'data:image/png;base64,'+resp);
							$('#video-upload-content-id').show();

						}, (e) => {
							console.log("Media1 File Not Found");
						});
					} else {
						this.apiService.videoView(irdataset, Media1, recId).subscribe(resp => {
							this.Media1Path = 'data:video/mp4;base64,'+resp;
							$('#video-upload-wrap-id').hide();
							$('#video-upload-image-id').hide();
							$('#video-upload-image-id-f-m').hide();
							$('#video-upload-video-id').attr('src', 'data:video/mp4;base64,'+resp);
							$('#video-upload-video-id-f-m').attr('src', 'data:video/mp4;base64,'+resp);
							$('#video-upload-content-id').show();
						}, (e) => {
							console.log("Media1 File Not Found");
						});
					}

					if(files[Media2].contentType.includes("image")) {
					 this.apiService.imageView(irdataset, Media2, recId).subscribe(resp => {
						 this.Media2Path = 'data:image/png;base64,'+resp;
						 $('#video-upload-wrap-id1').hide();
							$('#video-upload-video-id1').hide();
							$('#video-upload-video-id-f-m-1').hide();
							$('#video-upload-image-id1').attr('src', 'data:image/png;base64,'+resp);
							$('#video-upload-image-id-f-m-1').attr('src', 'data:image/png;base64,'+resp);
							$('#video-upload-content-id1').show();
					 }, (e) => {
						 console.log("Media2 File Not Found");
					 });
					} else {
						this.apiService.videoView(irdataset, Media2, recId).subscribe(resp => {
							this.Media2Path = 'data:video/mp4;base64,'+resp;
							$('#video-upload-wrap-id1').hide();
							$('#video-upload-image-id1').hide();
							$('#video-upload-image-id-f-m-1').hide();
							$('#video-upload-video-id1').attr('src', 'data:video/mp4;base64,'+resp);
							$('#video-upload-video-id-f-m-1').attr('src', 'data:video/mp4;base64,'+resp);
							$('#video-upload-content-id1').show();
						}, (e) => {
							console.log("Media2 File Not Found");
						});
					}

				}, (e) => {
					console.log(e);
				});
			} /*else {
				this.existingDatetime = '';
			}*/
		}

		this.getTowers();

		// Back to Top
		var btn = $('.backtotop');
		$(window).scroll(function() {
		if ($(window).scrollTop() > 300) {
			btn.addClass('show');
		} else {
			btn.removeClass('show');
		}
		});

		btn.on('click', function(e) {
			e.preventDefault();
			$('html, body').animate({scrollTop:0}, '300');
		});
		// Back to Top

		//console.log(this.towerid);

	}

	processTowerIdFromName(towerId) {
		var towerslist = JSON.parse(localStorage.getItem('towers'));
		var towerIdFromString = "";
		for(var i=0;i<towerslist.length;i++) {
			var obj = towerslist[i];
			if(obj.id === towerId && obj.value.toLowerCase().includes("Tower".toLowerCase())) {
				var matches = obj.value.match(/(\d+)/);
				if (matches) {
					towerIdFromString = matches[0];
				}
			}
		}
		if(towerIdFromString === "") {
			towerIdFromString = towerId;
		}

		return towerIdFromString;
	}

	getFieldValue(irdataJsonObject, field) {
		var r = irdataJsonObject.data;
		for(var key in r) {
			return r[field];
		}
	}

	// Fetch All Towers
	getTowers() {
		this.apiService.getTowers().subscribe(res => {

		  // console.log(res.value);
			// for(var i=0;i<res.value.length;i++) {
		    // console.log(this.sortAlphaNumeric(res.value, "ASC", "name"));
			var dataSorted = this.apiService.sortAlphaNumeric(res.value, "ASC", "name");

			for(var i=0;i<dataSorted.length;i++) {
			var item = {
				"id" : dataSorted[i].id,
				"value" : dataSorted[i].name
			  };
			 this.towersList.push(item);
		  }
			}, (e) => {
		  console.log(e);
			});

		// console.log(this.towersList);
	  }

	  convertLocalDateToUTCDate(date, toUTC) {
		date = new Date(date);
		//Local time converted to UTC
		// console.log("Time: " + date);
		var localOffset = date.getTimezoneOffset() * 60000;
		var localTime = date.getTime();
		if (toUTC) {
			date = localTime + localOffset;
		} else {
			date = localTime - localOffset;
		}
		date = new Date(date);
		// console.log("Converted time: " + date);
		return date;
	}

	  onSubmit() {
		// console.log('Your Incident form has been submitted', this.incidentForm.value);

		var currentDateTime = new Date();

		var selectedDateTime = this.incidentForm.value.latestDate.year+"/"+this.incidentForm.value.latestDate.month+"/"+this.incidentForm.value.latestDate.day+" "+currentDateTime.getHours()+":"+currentDateTime.getMinutes()+":"+currentDateTime.getSeconds();

		var selectedDateTimeUtcFormat = this.convertLocalDateToUTCDate(selectedDateTime, true);
		var finalUtcDateTimeValue = moment(selectedDateTimeUtcFormat).format("yyyy-MM-DDTHH:mm:ss")+".000Z"
		var createdDateOnSubmit = finalUtcDateTimeValue;

		// console.log(createdDateOnSubmit);
		// console.log(this.existingDatetime);
		// return true;
		// var timeToRemove = moment.duration("10:00:00");
		// var dateToPass = moment(selectedDateTime);
		// dateToPass.subtract(timeToRemove);
		// dateToPass.subtract(1, 'd');
		// var createdDateOnSubmit = moment(dateToPass).format("yyyy-MM-DDTHH:mm:ss")+".000Z";

		var createdDate = (this.existingDatetime === '' || this.existingDatetime == undefined) ? createdDateOnSubmit : this.existingDatetime;

		var towerIdFromString = this.processTowerIdFromName(this.incidentForm.value.towerid);

		var otherRespodningAgenciesData = [];
		if(this.incidentForm.value.otherRespondingAgencies1) otherRespodningAgenciesData.push("Police");
		if(this.incidentForm.value.otherRespondingAgencies2) otherRespodningAgenciesData.push("Ambulance");
		if(this.incidentForm.value.otherRespondingAgencies3) otherRespodningAgenciesData.push("Helicopter");
		if(this.incidentForm.value.otherRespondingAgencies4) otherRespodningAgenciesData.push("Fire Dept.");
		if(typeof(this.incidentForm.value.otherRespondingAgencies5) != 'undefined' && this.incidentForm.value.otherRespondingAgencies5 != null && this.incidentForm.value.otherRespondingAgencies5 != '' && this.incidentForm.value.otherRespondingAgencies5 != '-') otherRespodningAgenciesData.push(this.incidentForm.value.otherRespondingAgencies5);

		this.requestJson = {
			"Tower Id": towerIdFromString,
			"Time Of Incident" : this.incidentForm.value.timeOfIncident,
			"Time Notified" : this.incidentForm.value.timeNotified,
			"Time Terminated" : this.incidentForm.value.timeTerminated,
			"Patient Name" : this.incidentForm.value.patientName,
			"Nationality or Ethnicity" : this.incidentForm.value.nationalityEthnicity,
			"Nature Of Incident" : this.incidentForm.value.natureOfIncident,
			"Beach Location" : this.incidentForm.value.beachLocation,
			"Distance From Shore" : this.incidentForm.value.distanceFromShore,
			"Briefly Describe Patient" : this.incidentForm.value.brieflyDescribePatient,
			"How Alerted" : this.incidentForm.value.howAlerted,
			"In Flagged Area" : this.incidentForm.value.inFlaggedArea == 'Yes' ? 1 : 0,
			"Lifeguards Responding" : this.incidentForm.value.lifeguardsResponding,
			"Witness Name" : this.incidentForm.value.witnessName,
			"Surf Conditions" : this.incidentForm.value.surfConditions,
			"Swell Size" : this.incidentForm.value.swellSize,
			"Wind Direction" : this.incidentForm.value.windDirection,
			"Media Attended" : this.incidentForm.value.mediaAttended == 'Yes' ? 1 : 0,
			"Comments" : this.incidentForm.value.comments,
			"What was the level of consciousness" : this.incidentForm.value.patinetConscious,
			"The Airway Clear" : this.incidentForm.value.theAirwayPresent == 'Yes' ? 1 : 0,
			"Breathing Present" : this.incidentForm.value.breathingPresent == 'Yes' ? 1 : 0,
			"Pulse Present" : this.incidentForm.value.pulsePresent == 'Yes' ? 1 : 0,
			"Cpr Performed" : this.incidentForm.value.cprPerformed == 'Yes' ? 1 : 0,
			"O2 Airbag Resuscitator" : this.incidentForm.value.o2EquipmentUsed == 'Yes' ? 1 : 0,
			"Suction" : this.incidentForm.value.suction == 'Yes' ? 1 : 0,
			"Was Guedel Airway Used" : this.incidentForm.value.mechanicalResuscitation == 'Yes' ? 1 : 0,
			"Oxygen Therapy" : this.incidentForm.value.oxygenTherapy == 'Yes' ? 1 : 0,
			"Defibrillation" : this.incidentForm.value.defibrillation == 'Yes' ? 1 : 0,
			"Other FirstAid Rendered" : this.incidentForm.value.otherFirstaidRendered,
			"Patients Condition On Disposal" : this.incidentForm.value.patientsConditionOnDisposal,
			// "Other Responding Agencies" : this.incidentForm.value.otherRespondingAgencies,
			"Other Responding Agencies" : (otherRespodningAgenciesData.length == 0) ? '-' : otherRespodningAgenciesData.join(', '),
			"Patients Refused Attention" : this.incidentForm.value.patientsRefusedAttention,
			"Reporting Lifeguard" : this.incidentForm.value.reportingLifeguard,
			// "Lifeguard Superintendent" : this.incidentForm.value.lifeguardSuperintendent
		};

		// console.log(JSON.stringify(this.requestJson));
		const formData: FormData = new FormData();
		formData.append('jsondata', JSON.stringify(this.requestJson));
		var flagFileStatus = false;
		 var infoFileStatus = false;

		 if (typeof(this.selectedFiles1) != 'undefined' && this.selectedFiles1 != null) {
			 formData.append('file', this.selectedFiles1.item(0));
		 } else {
			 flagFileStatus = true;

			 if (this.Media1Path != undefined && typeof(this.Media1Path) != 'undefined'
				 && this.Media1Path != null) {
					 if(this.Media1Path.includes("image")) {
						var bytes = this.Media1Path.split(',')[0].indexOf('base64') >= 0 ? atob(this.Media1Path.split(',')[1]) : (<any>window).unescape(this.Media1Path.split(',')[1]);
						var mime = this.Media1Path.split(',')[0].split(':')[1].split(';')[0];
						var max = bytes.length;
						var ia = new Uint8Array(max);
						for (var i = 0; i < max; i++) {
							ia[i] = bytes.charCodeAt(i);
						}
						var flagFile1 = new File([ia], 'File1.png', { type: mime });
						formData.append('file', flagFile1);
					} else {
						var bytes = this.Media1Path.split(',')[0].indexOf('base64') >= 0 ? atob(this.Media1Path.split(',')[1]) : (<any>window).unescape(this.Media1Path.split(',')[1]);
						var mime = this.Media1Path.split(',')[0].split(':')[1].split(';')[0];
						var max = bytes.length;
						var ia = new Uint8Array(max);
						for (var i = 0; i < max; i++) {
							ia[i] = bytes.charCodeAt(i);
						}
						var flagFile1 = new File([ia], 'File1.mp4', { type: mime });
						formData.append('file', flagFile1);
					}
			 } else {
				var dummyFile1 = new File([""], "DummyFile1.png", {type: "image/png"})
				formData.append('file', dummyFile1);
			 }
		 }

		 if (typeof(this.selectedFiles2) != 'undefined' && this.selectedFiles2 != null) {
			 formData.append('file', this.selectedFiles2.item(0));
		 } else {
			 infoFileStatus = true;

			 if (this.Media2Path != undefined && typeof(this.Media2Path) != 'undefined'
				 && this.Media2Path != null) {
					 if(this.Media2Path.includes("image")) {
						var bytes = this.Media2Path.split(',')[0].indexOf('base64') >= 0 ? atob(this.Media2Path.split(',')[1]) : (<any>window).unescape(this.Media2Path.split(',')[1]);
						var mime = this.Media2Path.split(',')[0].split(':')[1].split(';')[0];
						var max = bytes.length;
						var ia = new Uint8Array(max);
						for (var i = 0; i < max; i++) {
							ia[i] = bytes.charCodeAt(i);
						}
						var flagFile2 = new File([ia], 'File2.png', { type: mime });
						formData.append('file', flagFile2);
					} else {
						var bytes = this.Media2Path.split(',')[0].indexOf('base64') >= 0 ? atob(this.Media2Path.split(',')[1]) : (<any>window).unescape(this.Media2Path.split(',')[1]);
						var mime = this.Media2Path.split(',')[0].split(':')[1].split(';')[0];
						var max = bytes.length;
						var ia = new Uint8Array(max);
						for (var i = 0; i < max; i++) {
							ia[i] = bytes.charCodeAt(i);
						}
						var flagFile2 = new File([ia], 'File2.mp4', { type: mime });
						formData.append('file', flagFile2);
					}
			 } else {
				var dummyFile2 = new File([""], "DummyFile2.png", {type: "image/png"})
				formData.append('file', dummyFile2);
			 }
		 }

		let adminRoleStatus = this.getUserRole();

		if(adminRoleStatus) {
			var irdataset = localStorage.getItem('irdataset');
			if (typeof(irdataset) != 'undefined' && irdataset != null) {
				this.showFormSubmissionLoader = true;
				this.apiService.postIncidentReport(formData, createdDate, irdataset, 'incidentreport').subscribe(res => {
					this.showFormSubmissionLoader = false;
					this.toastr.success('Success', 'Incident Report created successfully!');
					this.router.navigate(['/add']);
				}, (e) => {
					this.toastr.error('Failed', 'Invalid form data!');
				});
			} else {
				this.toastr.error('Failed', 'Please select tower / location !');
			}
		} else {

			var selectedDateTimeSpecificDate = this.incidentForm.value.latestDate.year+"/"+this.incidentForm.value.latestDate.month+"/"+this.incidentForm.value.latestDate.day+" 00:00:00";
			var timeToRemoveSpecificHours = moment.duration("10:00:00");
			var dateToPassSpecific = moment(selectedDateTimeSpecificDate);
			dateToPassSpecific.subtract(timeToRemoveSpecificHours);
			var createdDateAsPerZone = moment(dateToPassSpecific).format("yyyy-MM-DDTHH:mm:ss")+".000Z";

			// Check the Role Permission
			var createdDate1 = moment.utc(createdDateAsPerZone).tz("Australia/Brisbane").format("yyyy-MM-DDTHH:mm:ss")+".000Z";
			var currentDateTime1 = new Date();
			var aestCreatedDate1 = moment.utc(currentDateTime1).tz("Australia/Brisbane").format("yyyy-MM-DDTHH:mm:ss.000Z");
			var duration = moment(aestCreatedDate1).diff(moment.utc(createdDate1), 'days');
			// console.log(duration);

			var irdataset = localStorage.getItem('irdataset');
			if(duration <= 2) {
				if (typeof(irdataset) != 'undefined' && irdataset != null) {
					this.showFormSubmissionLoader = true;
					this.apiService.postIncidentReport(formData, createdDate, irdataset, 'incidentreport').subscribe(res => {
						this.showFormSubmissionLoader = false;
						this.toastr.success('Success', 'Incident Report created successfully!');
						this.router.navigate(['/add']);
					}, (e) => {
						this.toastr.error('Failed', 'Invalid form data!');
					});
				} else {
					this.toastr.error('Failed', 'Please select tower / location !');
				}
			} else {
				this.toastr.error('Failed', 'Sorry, you do not have permission to edit this record. Please contact your system administrator');
				return false;
			}
		}
	  }

	  getUserRole() {
		let adminRoleStatus = false;
		let userInfo = localStorage.getItem('userInfo');
		let groups = JSON.parse(userInfo).groups;
		let organizationToCheck = "Organisation "+APP_SETTINGS.ORGANIZATION_ID;
		for(var i=0;i<groups.length;i++) {
			if(groups[i].toLowerCase().includes(organizationToCheck.toLowerCase())) {
				if(groups[i].toLowerCase().includes("Administrator".toLowerCase()) ||
					groups[i].toLowerCase().includes("Admin".toLowerCase())) {
						adminRoleStatus = true;
				}
			}
		}

		return adminRoleStatus;
	 }

	  fetchDataset(towerid) {

		localStorage.setItem('selectedtowerid', this.towerid+"");
		localStorage.setItem('selecteddate', JSON.stringify(this.latestDate));

		localStorage.removeItem('brdataset');
		localStorage.removeItem('irdataset');
		localStorage.removeItem('codataset');
		localStorage.removeItem('eqdataset');

		var beachApi = false;
		var incidentApi = false;
		var conditionApi = false;
		var equipmentApi = false;

		// Bach
		this.apiService.fetchDatasetByTowerIdAndLocation(towerid, "beach").subscribe(res => {
			for(var i=0;i<res.value.results.length;i++) {
				if(res.value.results[i].location == towerid &&
					res.value.results[i].name.toLowerCase().includes(APP_SETTINGS.LIFEGUARD_BEACH_DATA.toLowerCase())) {
					localStorage.setItem('brdataset', res.value.results[i].id+"");
				}
			}
			beachApi = true;
		}, (e) => {
			console.log(e);
		});

		// Incident
		this.apiService.fetchDatasetByTowerIdAndLocation(towerid, "incident").subscribe(res => {
			for(var i=0;i<res.value.results.length;i++) {
				if(res.value.results[i].location == towerid &&
					res.value.results[i].name.toLowerCase().includes(APP_SETTINGS.LIFEGUARD_INCIDENT_DATA.toLowerCase())) {
					localStorage.setItem('irdataset', res.value.results[i].id+"");
				}
			}
			incidentApi = true;
		}, (e) => {
			console.log(e);
		});

		// Conditions
		this.apiService.fetchDatasetByTowerIdAndLocation(towerid, "condition").subscribe(res => {
			for(var i=0;i<res.value.results.length;i++) {
				if(res.value.results[i].location == towerid &&
					res.value.results[i].name.toLowerCase().includes(APP_SETTINGS.LIFEGUARD_CONDITIONS_DATA.toLowerCase())) {
					localStorage.setItem('codataset', res.value.results[i].id+"");
				}
			}
			conditionApi = true;
		}, (e) => {
			console.log(e);
		});

		// Equipment
		this.apiService.fetchDatasetByTowerIdAndLocation(towerid, "equipment").subscribe(res => {
			for(var i=0;i<res.value.results.length;i++) {
				if(res.value.results[i].location == towerid &&
					res.value.results[i].name.toLowerCase().includes(APP_SETTINGS.LIFEGUARD_EQUIPMENT_DATA.toLowerCase())) {
					localStorage.setItem('eqdataset', res.value.results[i].id+"");
				}
			}
			equipmentApi = true;
		}, (e) => {
			console.log(e);
		});

		// After all load
		// if(beachApi && incidentApi && conditionApi && equipmentApi) {
		//	this.dataReload();
		// }
		this.dataReload();
	}

	getValueFromList(checkValue, dataRecord) {
		for(var i=0;i<dataRecord.length;i++) {
			if(dataRecord[i].name === checkValue) {
				return dataRecord[i].identifier;
			}
		}
	}

	// Logout User
	logOut() {
		this.toastr.success('Success', "Logged Out Successfully");
		this.apiService.clearSession();
		this.router.navigate(['/login']);
	}

	latestDateChanged(changes: KeyValueChanges<string, any>) {
		this.dataReload();
	  }

	ngDoCheck(): void {
		const changes = this.latestDateDiffer.diff(this.latestDate);
		if (changes) {
		  this.latestDateChanged(changes);
		}
	}

	dataReload() {
		this.incidentForm = this.formBuilder.group({
			date : this.date,
			towerid: this.towerid,
			towersList: this.towersList,
			latestDate: this.latestDate,

			timeOfIncident : '',
			timeNotified : '',
			timeTerminated : '',
			patientName	: '',
			nationalityEthnicity : '',
			natureOfIncident : '',
			beachLocation : '',
			distanceFromShore : '',
			brieflyDescribePatient : '',
			howAlerted : '',
			inFlaggedArea : false,
			lifeguardsResponding : '',
			witnessName	: '',
			surfConditions	: '',
			swellSize : 0,
			windDirection : '',
			mediaAttended : false,
			comments : '',
			patinetConscious : '',
			theAirwayPresent : false,
			breathingPresent : false,
			pulsePresent : false,
			cprPerformed : false,
			o2EquipmentUsed : false,
			suction : false,
			mechanicalResuscitation : false,
			oxygenTherapy : false,
			defibrillation : false,
			otherFirstaidRendered : '',
			patientsConditionOnDisposal	: '',
			otherRespondingAgencies	: '',
			patientsRefusedAttention : '',
			reportingLifeguard : '',
			// lifeguardSuperintendent	: '',
			Media1: null,
			Media2: null,

			otherRespondingAgencies1: false,
			otherRespondingAgencies2: false,
			otherRespondingAgencies3: false,
			otherRespondingAgencies4: false,
			otherRespondingAgencies5: ''
		});

		// this.existingDatetime = '';

		$('#flag_placement').replaceWith($('#flag_placement').clone());
		$('#video-upload-content-id').hide();
		$('#video-upload-wrap-id').show();

		$('#information_sign').replaceWith($('#information_sign').clone());
		$('#video-upload-content-id1').hide();
		$('#video-upload-wrap-id1').show();

		// this.router.navigate(['/add']);
	}


	// For Flag Placement File Upload
	selectFile1(event) {
		this.selectedFiles1 = event.target.files;

		var reader = new FileReader();
		reader.onload = function(e) {
			$('#video-upload-wrap-id').hide();
			$('#video-upload-image-id').show();
			$('#video-upload-video-id').show();

			if(event.target.files[0].name.includes("mp4")) {
				$('#video-upload-video-id').attr('src', URL.createObjectURL(event.target.files[0]));
				$('#video-upload-video-id').attr('alt', event.target.files[0].name);

				$('#video-upload-image-id').hide();
			} else {

				$('#video-upload-image-id').attr('src', URL.createObjectURL(event.target.files[0]));
				$('#video-upload-image-id').attr('alt', event.target.files[0].name);

				$('#video-upload-video-id').hide();
			}
			$('#video-upload-content-id').show();

			$('#image-title-id').html(event.target.files[0].name);
		};
		reader.readAsDataURL(event.target.files[0]);
	}

	removeUpload1() {
		if(confirm("Are you sure to remove this Media file?")) {
			$('#flag_placement').replaceWith($('#flag_placement').clone());
			$('#video-upload-content-id').hide();
			$('#video-upload-wrap-id').show();

			$('#Media1').val('');
			this.Media1Path = null;
		}
	}


	// For Information Sign File Upload
	selectFile2(event) {
		this.selectedFiles2 = event.target.files;

		var reader = new FileReader();
		reader.onload = function(e) {
			$('#video-upload-wrap-id1').hide();

			$('#video-upload-image-id1').show();
			$('#video-upload-video-id1').show();

			if(event.target.files[0].name.includes("mp4")) {
				$('#video-upload-video-id1').attr('src', URL.createObjectURL(event.target.files[0]));
				$('#video-upload-video-id1').attr('alt', event.target.files[0].name);

				$('#video-upload-image-id1').hide();
			} else {

				$('#video-upload-image-id1').attr('src', URL.createObjectURL(event.target.files[0]));
				$('#video-upload-image-id1').attr('alt', event.target.files[0].name);

				$('#video-upload-video-id1').hide();
			}

			$('#video-upload-content-id1').show();

			$('#image-title-id1').html(event.target.files[0].name);
		};
		reader.readAsDataURL(event.target.files[0]);
	}

	removeUpload2() {
		if(confirm("Are you sure to remove this Media file?")) {
			$('#information_sign').replaceWith($('#information_sign').clone());
			$('#video-upload-content-id1').hide();
			$('#video-upload-wrap-id1').show();

			$('#Media2').val('');
			this.Media2Path = null;
		}
	}
}

/**
 * Created By : Venkat Salagrama
 */
